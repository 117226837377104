import { useState, memo, FC, MouseEventHandler } from 'react';
import Link from 'next/link';
import { Icons } from '@studenten/ui-components';

import { HeaderMenu, HeaderMenuItem } from 'api_entities/strapi/types';

import { Container, List } from './MenuDropdown.styles';

export type MenuDropdownProps = {
  menuItems: HeaderMenuItem[];
  subItems: HeaderMenu['attributes']['submenu'];
};

const MenuDropdown: FC<MenuDropdownProps> = ({ menuItems, subItems }) => {
  const [activeKey, setActiveKey] = useState<string | null>(null);
  const handleOnContainerLeave: MouseEventHandler<HTMLDivElement> = () => setActiveKey(null);

  const handleMouseEnter = (key?: string): void => setActiveKey(key || null);

  const submenu = subItems?.find(({ key }) => key === activeKey);

  return (
    <Container onMouseLeave={handleOnContainerLeave}>
      <div>
        <List>
          {menuItems.map(({ id, label, href, subMenuKey }) => (
            <li key={id} onMouseEnter={() => handleMouseEnter(subMenuKey)}>
              {href ? (
                <Link href={href || ''} prefetch={false}>
                  <a>
                    <span>{label}</span>
                    {subMenuKey && <Icons.RightArrow />}
                  </a>
                </Link>
              ) : (
                <div>
                  <span>{label}</span>
                  {subMenuKey && <Icons.RightArrow />}
                </div>
              )}
            </li>
          ))}
        </List>
      </div>
      {activeKey && (
        <div>
          <List>
            {submenu?.items?.map(({ id, label, href }) => (
              <li key={id}>
                <Link href={href} prefetch={false}>
                  <a>
                    <span>{label}</span>
                  </a>
                </Link>
              </li>
            ))}
          </List>
        </div>
      )}
    </Container>
  );
};

export default memo(MenuDropdown);
