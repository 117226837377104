import Link from 'next/link';
import { useRouter } from 'next/router';
import { useRef, useEffect, useState, MouseEvent as ReactMouseEvent } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import { useAnimation } from 'framer-motion';
import { Drawer, Overlay, Icons, Button } from '@studenten/ui-components';

import { APP_ROUTES } from 'constants/routes';
import { useOutsideClick } from 'features/layout/hooks/useOutsideClick';

import {
  Container,
  Categories,
  SubCategories,
  Header,
  AuthButtonsContainer,
  BackButtonContainer,
  ListContainer,
  StyledDivider,
} from './MobileMenu.styled';
import type { Props } from './types';

export const MobileMenu = ({ isOpen, setOpen, menuItems, subItems }: Props) => {
  const router = useRouter();
  const [menuStep, setMenuStep] = useState<number>(1);
  const [activeKey, setActiveKey] = useState<string | null>(null);
  const drawerRef = useRef<HTMLDivElement | null>(null);
  const subCategoriesControls = useAnimation();

  useEffect(() => {
    subCategoriesControls.start({
      transition: { duration: 0.5 },
      x: menuStep === 1 ? 278 : 0,
    });
  }, [menuStep]);

  const handleCloseMenu = () => {
    setOpen(false);
  };

  const handleClickMenuItem = (
    e: ReactMouseEvent<HTMLAnchorElement | HTMLDivElement, MouseEvent>,
    key?: string,
    href?: string
  ): void => {
    e.preventDefault();
    if (key) {
      setActiveKey(key);
      setMenuStep(2);
    } else if (href) {
      void router.push(href);
    } else {
      handleCloseMenu();
    }
  };

  const menuCategories = menuItems?.map((menu) => (menu?.items ? menu.items : [])).flat();
  const submenu = subItems?.find(({ key }) => key === activeKey);

  useOutsideClick(drawerRef, handleCloseMenu);
  return (
    <RemoveScroll enabled={isOpen}>
      <Drawer isOpen={isOpen} ref={drawerRef}>
        <Container>
          <Categories>
            <Header>
              <Button onClick={handleCloseMenu}>
                <Icons.Cross size="large" />
              </Button>
              <AuthButtonsContainer>
                <Link href={APP_ROUTES.PARTNERS_LOGIN} passHref prefetch={false}>
                  <Button variant="base" as="a">
                    Inloggen
                  </Button>
                </Link>
                <Link href={APP_ROUTES.PARTNERS_SIGN_UP} passHref prefetch={false}>
                  <Button variant="base" as="a">
                    Inhuren
                  </Button>
                </Link>
              </AuthButtonsContainer>
            </Header>
            <StyledDivider />
            <ListContainer>
              <ul>
                {menuCategories.map(({ id, label, href, subMenuKey }) => (
                  <li key={id}>
                    {href ? (
                      <Link href={href || ''} prefetch={false}>
                        <a onClick={(e) => handleClickMenuItem(e, subMenuKey, href)}>
                          <span>{label}</span>
                          {subMenuKey && <Icons.RightArrow />}
                        </a>
                      </Link>
                    ) : (
                      <div onClick={(e) => handleClickMenuItem(e, subMenuKey, href)}>
                        <span>{label}</span>
                        {subMenuKey && <Icons.RightArrow />}
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </ListContainer>
          </Categories>
          <SubCategories animate={subCategoriesControls}>
            <Header>
              <BackButtonContainer>
                <Button onClick={() => setMenuStep(1)}>
                  <Icons.LeftArrow />
                  Back
                </Button>
              </BackButtonContainer>
            </Header>
            <StyledDivider />
            <ListContainer>
              <ul>
                {activeKey &&
                  submenu?.items?.map(({ id, label, href }) => (
                    <li key={id}>
                      <Link href={href} passHref prefetch={false}>
                        <a>
                          <span>{label}</span>
                        </a>
                      </Link>
                    </li>
                  ))}
              </ul>
            </ListContainer>
          </SubCategories>
        </Container>
      </Drawer>
      <Overlay isOpen={isOpen} />
    </RemoveScroll>
  );
};
