import { device } from 'constants/device';

import styled from 'styled-components';

export const Container = styled.header<{ isDark?: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 10;
  height: ${({ theme }) => theme.v2.dimensions.headerHeight};
  background-color: transparent;
  display: flex;
  align-items: center;
  @media ${device.laptop} {
    height: ${({ theme }) => theme.v2.dimensions.mobileHeaderHeight};
  }
`;

export const Content = styled.div`
  padding: 0 108px;
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  @media ${device.laptop} {
    padding: 0 58px;
  }
  @media ${device.mobileL} {
    padding: 0 16px;
  }
`;

export const LogoLink = styled.a`
  display: inline-block;
  margin-right: 16px;
  overflow: hidden;
`;

export const NavLink = styled.a<{ isDark?: boolean }>`
  display: flex;
  gap: 9px;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${({ theme }) => theme.business.white};
  transition: color 0.1s ease-in;

  &:hover {
    color: ${({ theme }) => theme.business.green};
  }
`;

export const Navigation = styled.nav`
  flex: 1;
  margin-right: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media ${device.laptop} {
    display: none;
  }

  & > * {
    margin-left: 16px;
  }

  ${NavLink} {
    display: inline-block;
    padding: 10px 32px;
  }
`;

export const AuthButtonsContainer = styled.div`
  flex-basis: 214px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${device.laptop} {
    display: none;
  }
`;

export const SignUpLink = styled(NavLink)`
  position: relative;
  display: flex;
  padding: 10px 32px;
  background-color: #1dbf70;
  border-radius: 8px;
  transition: background-color 0.1s ease-in;

  &:hover {
    background-color: #20d47c;
    color: ${({ theme }) => theme.system.white};
  }
`;

export const MobileMenuButton = styled.div<{ contrast?: boolean }>`
  display: none;
  @media ${device.laptop} {
    display: block;
    button {
      padding: 0;

      svg {
        color: ${({ theme }) => theme.system.white};
      }
    }
  }
`;

export const CategoriesLinkWrapper = styled.div`
  padding: 8px 20px;
  display: inline-flex;
  align-items: center;
  height: 100%;

  a {
    display: inline-flex;
  }
`;

export const LinkIconWrapper = styled.span`
  display: inline-block;
  margin-left: 8px;
  rotate: 90deg;

  & > * {
    border-color: ${({ theme }) => theme.system.white};
  }
`;

export const CategoriesList = styled.div`
  height: 508px;
  width: 560px;
  position: absolute;
  top: ${({ theme }) => `calc(${theme.v2.dimensions.headerHeight} - 16px)`};
`;

export const CategoriesContainer = styled.div`
  position: relative;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.system.white};
  height: ${({ theme }) => theme.v2.dimensions.headerHeight};
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.business.green};

    ${LinkIconWrapper} {
      & > * {
        border-color: ${({ theme }) => theme.business.green};
      }
    }
  }
  & > ${CategoriesList} {
    display: none;
  }
  & > ${CategoriesLinkWrapper}:hover ~ ${CategoriesList} {
    display: block;
  }
  & > ${CategoriesList}:hover {
    display: block;
  }
  & > ${CategoriesLinkWrapper}:active ~ ${CategoriesList} {
    display: block;
  }
  & > ${CategoriesList}:active {
    display: block;
  }
`;

export const BackIconWrapper = styled.span`
  align-self: center;
  display: inline-flex;
  margin-right: 8px;
  rotate: 180deg;
`;
