import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  width: 278px;
  background-color: ${({ theme }) => theme.business.cyan};
  height: 100vh;
  overflow: hidden;
`;

export const Header = styled.div`
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;

export const AuthButtonsContainer = styled.div`
  flex: 0 1 178px;
  display: flex;
  justify-content: space-between;
  a {
    font-size: 1.2rem;
    width: 81px;
    height: 38px;
    color: ${({ theme }) => theme.system.white};
  }
`;

export const BackButtonContainer = styled.div`
  button {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: ${({ theme }) => theme.system.white};
  }
  span {
    margin-right: 18px;

    border-color: ${({ theme }) => theme.system.white};
  }
`;

export const Categories = styled(motion.div)`
  z-index: 1;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  background-color: inherit;
  & > ${Header} {
    svg {
      color: ${({ theme }) => theme.system.white};
    }
  }
`;

export const SubCategories = styled(motion.div)`
  transform: translateX(278px);
  /* transition: transform 250ms cubic-bezier(0, 0, 0.38, 0.9); */
  height: 100%;
  z-index: 2;
  position: relative;
  left: 0;
  top: 0;
  right: 0;
  background-color: inherit;
`;

export const ListContainer = styled.div`
  margin: 12px 0;
  overflow-y: scroll;
  max-height: 100%;
  ul > li {
    color: ${({ theme }) => theme.system.white};
    & > a,
    div {
      padding: 12px 30px 12px 16px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > span:first-child {
        margin-right: 9px;
        display: inline-block;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      & > span:nth-child(2) {
        height: 8px;
        width: 8px;
        border-color: ${({ theme }) => theme.system.white};
      }
    }
  }
`;

export const StyledDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.business.lightCyan};
`;
