import Image from 'next/image';
import Link from 'next/link';
import { size } from 'constants/device';
import { APP_ROUTES } from 'constants/routes';

import { useWindowSize } from 'features/layout/hooks/useWindowSize';
import { Icons } from '@studenten/ui-components';
import Logo from 'public/assets/werkgever-logo.svg';

import {
  StyledFooter,
  Container,
  Navigation,
  LogoContainer,
  SupportContainer,
  SocialNetworks,
  NavLink,
} from './Footer.styled';

export const Footer = () => {
  const { width } = useWindowSize();
  return (
    <StyledFooter id="footer">
      <Container>
        <LogoContainer>
          {width <= size.laptop ? (
            <Image layout="fixed" src={Logo} alt="studenten.nl footer logo" />
          ) : (
            <Image layout="fixed" src={Logo} alt="studenten.nl footer logo" />
          )}
          <Link passHref href={APP_ROUTES.HOME} prefetch={false}>
            <NavLink style={{ display: 'flex', alignItems: 'center' }}>
              <Image src="/assets/left.svg" alt="Arrow" width={24} height={24} />
              Studenten
            </NavLink>
          </Link>
        </LogoContainer>
        <Navigation>
          <div>
            <b>Services</b>
            <Link href={APP_ROUTES.WERVING}>
              <a>Werving & Selectie</a>
            </Link>
            <Link href={APP_ROUTES.UITZENDBUREAU}>
              <a>Uitzenden</a>
            </Link>
            <Link href={APP_ROUTES.JOBBOARD}>
              <a>Jobboard</a>
            </Link>
            {/* <Link href="/studenten-uitzendbureau">
              <a>Recruitment</a>
            </Link> */}
          </div>
          <div>
            <b>Bedrijf</b>
            <Link href={APP_ROUTES.ABOUT_US}>
              <a>Over ons</a>
            </Link>
            <Link href={APP_ROUTES.BLOG}>
              <a>Blog</a>
            </Link>
            <Link href={APP_ROUTES.CONTACTS}>
              <a>Contact</a>
            </Link>
            <Link href={APP_ROUTES.PRIVACY_STATEMENT}>
              <a>Privacy policy</a>
            </Link>
          </div>
        </Navigation>
        <SupportContainer>
          <h6>Support</h6>
          <p>
            Vragen of feedback?
            <br />
            Neem contact met ons op. Wij horen graag van jou!
          </p>
          <SocialNetworks>
            <a
              title="Follow us on twitter."
              rel="noopener noreferrer"
              href="https://x.com/Studentennl?s=20&t=Um_A2HbTmYfcTxuB68xeyw"
            >
              <Icons.Twitter />
            </a>
            <a
              title="Subscribe to YouTube channel."
              href="https://www.youtube.com/@studentenuitzendbureau"
            >
              <Icons.YouTube />
            </a>
            <a title="Follow us on Facebook." href="https://www.facebook.com/www.studenten.nl">
              <Icons.Facebook />
            </a>
            <a
              title="Follow us on Instagram."
              href="https://www.instagram.com/studenten.nl_official"
            >
              <Icons.Instagram />
            </a>
          </SocialNetworks>
        </SupportContainer>
      </Container>
    </StyledFooter>
  );
};
