import { FC } from 'react';

import Header from './components/Header';
import Footer from './components/Footer';

import { Main } from './BusinessLayout.styled';

export const BusinessLayout: FC = ({ children }) => {
  return (
    <>
      <Header />
      <Main id="main-layout">{children}</Main>
      <Footer />
    </>
  );
};
