import { device } from 'constants/device';

import styled from 'styled-components';

export const Main = styled.main`
  background-color: ${({ theme }) => theme.system.light};
  min-height: ${({ theme }) => `calc(100vh - 308px - ${theme.v2.dimensions.headerHeight})`};
  position: relative;
  @media ${device.laptop} {
    min-height: ${({ theme }) =>
      `calc(100vh - ${theme.v2.dimensions.mobileFooterHeight} - ${theme.v2.dimensions.mobileHeaderHeight})`};
  }
`;
