import { APP_ROUTES } from 'constants/routes';

import { memo, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Button, Icons } from '@studenten/ui-components';
import { size } from 'constants/device';
import { useWindowSize } from '../../../../../templates/ProductPage/hooks/useWindowSize';
import { useHeaderMenu } from 'hooks/useHeaderMenu';
import { MenuDropdown } from './components/MenuDropdown';
import MobileMenu from './components/MobileMenu';

import {
  Container,
  Navigation,
  Content,
  NavLink,
  SignUpLink,
  AuthButtonsContainer,
  LogoLink,
  MobileMenuButton,
  CategoriesContainer,
  CategoriesLinkWrapper,
  CategoriesList,
  LinkIconWrapper,
  BackIconWrapper,
} from './Header.styled';

export const Header = memo(() => {
  const router = useRouter();
  const headerMenuData = useHeaderMenu('business');

  const [isMobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);

  const { width: windowWidth } = useWindowSize();
  const isMobile = windowWidth < size.laptop;

  const isHomePage = router.pathname === APP_ROUTES.HOME;
  const logoSrc = '/assets/werkgever-logo.svg';

  return (
    <Container isDark={isHomePage}>
      {isMobile && headerMenuData && (
        <MobileMenu
          isOpen={isMobileMenuOpen}
          setOpen={setMobileMenuOpen}
          menuItems={headerMenuData.menu}
          subItems={headerMenuData.submenu}
        />
      )}
      <Content>
        <Link passHref href={APP_ROUTES.WERKGEVER} prefetch={false}>
          <LogoLink>
            <Image
              priority
              src={logoSrc}
              layout="fixed"
              width={228}
              height={36}
              alt="Studenten logo."
            />
          </LogoLink>
        </Link>

        {!isMobile && (
          <>
            <Navigation>
              <CategoriesContainer>
                <CategoriesLinkWrapper>
                  <Link passHref href={APP_ROUTES.HOME} prefetch={false}>
                    <a>
                      <BackIconWrapper>
                        <Icons.Arrow />
                      </BackIconWrapper>
                      Studenten
                    </a>
                  </Link>
                </CategoriesLinkWrapper>
              </CategoriesContainer>

              {headerMenuData &&
                headerMenuData.menu.map((menu) => (
                  <CategoriesContainer key={menu.id}>
                    <CategoriesLinkWrapper>
                      {menu.href ? (
                        <Link href={menu.href} prefetch={false} passHref>
                          <a>{menu.label}</a>
                        </Link>
                      ) : (
                        menu.label
                      )}
                      {menu.items?.length !== 0 && (
                        <LinkIconWrapper>
                          <Icons.RightArrow />
                        </LinkIconWrapper>
                      )}
                    </CategoriesLinkWrapper>
                    <CategoriesList>
                      <MenuDropdown menuItems={menu.items} subItems={headerMenuData.submenu} />
                    </CategoriesList>
                  </CategoriesContainer>
                ))}
            </Navigation>
            <AuthButtonsContainer>
              <Link passHref href={APP_ROUTES.PARTNERS_LOGIN} prefetch={false}>
                <NavLink>Log In</NavLink>
              </Link>
              <Link passHref href={APP_ROUTES.PARTNERS_SIGN_UP} prefetch={false}>
                <SignUpLink>Inhuren</SignUpLink>
              </Link>
            </AuthButtonsContainer>
          </>
        )}

        {isMobile && (
          <MobileMenuButton contrast={isHomePage}>
            <Button variant="base" onClick={() => setMobileMenuOpen(true)}>
              <Icons.Burger />
            </Button>
          </MobileMenuButton>
        )}
      </Content>
    </Container>
  );
});
