import { device } from 'constants/device';

import styled from 'styled-components';

export const StyledFooter = styled.footer`
  padding: 60px 100px;
  width: 100%;
  background-color: ${({ theme }) => theme.business.cyan};
  height: 308px;
  @media ${device.laptop} {
    padding-top: 60px;
    padding-left: 20px;
    padding-right: 40px;
    padding-bottom: 60px;
    height: initial;
  }
  @media ${device.tablet} {
    padding-top: 32px;
    padding-left: 16px;
    padding-right: 32px;
    padding-bottom: 40px;
    height: initial;
  }
`;

export const Container = styled.div`
  max-width: 1240px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  @media ${device.laptopL} {
    max-width: ${({ theme }) => theme.v2.dimensions.laptopPageWidth};
  }
  @media ${device.tablet} {
    max-width: 100vw;
    flex-direction: column;
  }
`;

export const LogoContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media ${device.tablet} {
    margin-bottom: 32px;
  }
`;

export const Navigation = styled.section`
  display: flex;
  flex: 2;
  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    @media ${device.tablet} {
      padding: 0;
    }
  }
  a {
    font-size: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: ${({ theme }) => theme.business.white};
    transition: color 0.1s ease-in;

    &:hover {
      color: ${({ theme }) => theme.business.lightGray};
    }
    @media ${device.tablet} {
      font-size: 1.4rem;
    }
  }
  b {
    font-size: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin-bottom: 16px;
    color: ${({ theme }) => theme.business.white};
  }

  a ~ a {
    margin-top: 20px;
  }
`;

export const SupportContainer = styled.section`
  flex: 1;
  color: ${({ theme }) => theme.business.white};
  @media ${device.tablet} {
    margin-top: 40px;
    margin-bottom: 16px;
  }
  & > h6 {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.2;
    margin-bottom: 11px;
    @media ${device.tablet} {
      font-size: 1.4rem;
    }
  }
  & > p {
    font-size: 1.4rem;
    line-height: 160%;
    margin-bottom: 29px;
  }
`;

export const SocialNetworks = styled.div`
  a {
    display: inline-block;
    width: 25px;
    height: 25px;
  }

  color: ${({ theme }) => theme.business.white};
  a ~ a {
    margin-left: 33px;
  }
  svg:hover {
    color: ${({ theme }) => theme.business.lightGray};
  }
`;

export const NavLink = styled.a<{ isDark?: boolean }>`
  display: flex;
  gap: 9px;
  font-size: 1.6rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${({ theme }) => theme.business.white};
  transition: color 0.1s ease-in;

  &:hover {
    color: ${({ theme }) => theme.business.lightGray};
  }

  img {
    vertical-align: middle;
  }
`;
