import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  & > div {
    background: ${({ theme }) => theme.business.cyan};
    border: 1px solid ${({ theme }) => theme.business.cyan};
    border-radius: 16px;
    width: 50%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: 0 12.5px 28.125px 0 rgba(5, 28, 37, 0.19);
  }

  & > div:first-child {
    border-right: 0;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > li {
    color: ${({ theme }) => theme.system.white};

    &:first-child > a,
    div {
      padding-top: 20px;
    }

    & > a,
    div {
      padding: 12px 16px 12px 24px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover {
        color: ${({ theme }) => theme.business.green};
        & > span:nth-child(2) {
          border-color: ${({ theme }) => theme.business.green};
        }
      }

      & > span:first-child {
        margin-right: 9px;
        display: inline-block;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 1.2;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      & > span:nth-child(2) {
        height: 8px;
        width: 8px;
        border-color: ${({ theme }) => theme.system.white};
      }
    }
  }
`;
